import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  weight: 0,
  is_active: false,
  screen: null,
  elements_count: 10,
  elements: [
    {
      id: 1,
      count: 3,
      entity_type_for_query: '',
      element_array: [
        {
          id: 1,
          element_type: '',
          position: 1,
          primary_banner_url: '',
          secondary_banner_url: '',
        },
        {
          id: 2,
          element_type: '',
          position: 2,
          primary_banner_url: '',
          secondary_banner_url: '',
        },
        {
          id: 3,
          element_type: '',
          position: 3,
          primary_banner_url: '',
          secondary_banner_url: '',
        },
      ],
      frame_position: 0,
    },
  ],
  feed_type: 'Банер 5х1',
};

export const feedSlice = createSlice({
  name: 'feedSlice',
  initialState,
  reducers: {
    setName(state, { payload }: PayloadAction<string>) {
      state.name = payload;
    },
    setIsActive(state, action: PayloadAction<boolean>) {
      state.is_active = action.payload;
    },
    setElementsCount(state, action: PayloadAction<number>) {
      state.elements_count = action.payload;
    },
    setElements(state, action: PayloadAction<any[]>) {
      state.elements = [...action.payload];
    },
    setFeedType(state, action: PayloadAction<any>) {
      state.feed_type = action.payload;
    },
    setScreen(state, action: PayloadAction<any>) {
      state.screen = action.payload;
    },
    setWeight(state, action: PayloadAction<any>) {
      state.weight = action.payload;
    },
    setUpdatedElements(
      state,
      action: PayloadAction<{ id: number; updatedArray: any[] }>
    ) {
      const { id, updatedArray } = action.payload;
      state.elements = state.elements.map((element) =>
        element.id === id
          ? {
              ...element,
              element_array: updatedArray.map((item) => ({ ...item })),
            }
          : element
      );
    },
    updateElementPositions(state, action: PayloadAction<number[]>) {
      const idArray = action.payload;

      state.elements = state.elements.map((element) => ({
        ...element,
        element_array: element.element_array.map((item) => {
          const newPosition = idArray.indexOf(item.id) + 1;

          return {
            ...item,
            position: newPosition > 0 ? newPosition : item.position,
          };
        }),
      }));
    },
    setFeedData(state, action: PayloadAction<Partial<typeof initialState>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const {
  setName,
  setElements,
  setElementsCount,
  setFeedType,
  setIsActive,
  setUpdatedElements,
  setScreen,
  setWeight,
  updateElementPositions,
  setFeedData,
} = feedSlice.actions;
export const feedSliceReducer = feedSlice.reducer;
