import { FeedGroup, IBody, IEdit, IResponseGetById, IRoot } from './types';
import { Feed } from '../feeds/types';

interface ICreteFeedsGroup {
  name: string;
  weight: number;
  is_active: boolean;
  // feedTop: number;
  elements?: Array<{
    position: number;
    feed_id: number;
  }>;
  // feedBot: number;
}

// interface newFeedGroupCreatePayload extends Omit<IBody, 'feeds'> {
//   elements?: Array<IElement>;
// }

interface newFeedGroupUpdatePayload extends Omit<IBody, 'feeds'> {
  elements?: Array<{
    position: number;
    feed_id: number;
  }>;
}

interface IUpdateFeedGroup {
  id: number;
  payload: newFeedGroupUpdatePayload;
}

interface IElement {
  id: number;
  position: number;
  uuid: string;
  feed: Feed;
}

interface newFeedGroupType extends Omit<FeedGroup, 'feeds'> {
  elements: Array<IElement>;
}

export interface IResponseGetByIdAdapter extends IRoot {
  response: newFeedGroupType;
}

export const createFeedsGroupAdapter = (
  data: IBody
): ICreteFeedsGroup | never => {
  if (!data) {
    throw new Error('createFeedsGroupAdapter: not found data');
  }
  const { feeds, ...rest } = data;
  const elements = [] as Array<{
    position: number;
    feed_id: number;
  }>;

  if (feeds?.length) {
    Object.assign(
      elements,
      feeds.map((feedId, index) => ({
        position: index + 1,
        feed_id: feedId,
      }))
    );

    return {
      ...rest,
      elements,
    };
  }

  return {
    ...rest,
  };
};

export const updateFeedsGroupAdapter = (
  data: IEdit
): IUpdateFeedGroup | never => {
  if (!data) {
    throw new Error('createFeedsGroupAdapter: not found data');
  }
  const { payload, id } = data;

  const { feeds, ...restPayload } = payload;

  const elements = [] as Array<{
    position: number;
    feed_id: number;
  }>;

  if (feeds?.length) {
    Object.assign(
      elements,
      feeds.map((feedId, index) => ({
        position: index + 1,
        feed_id: feedId,
      }))
    );

    return {
      id,
      payload: {
        ...restPayload,
        elements,
      },
    };
  }

  return {
    id,
    payload: {
      ...restPayload,
    },
  };
};

export const getFeedGroupByIdAdapter = (
  data: IResponseGetByIdAdapter
): IResponseGetById => {
  if (!data) {
    throw new Error('getFeedGroupByIdAdapter: not found data');
  }
  const {
    response: { elements, ...restPayload },
    ...restRootResponse
  } = data;

  const feeds: Feed[] = elements
    .sort((a, b) => a?.position - b?.position)
    .map((element) => ({
      ...element?.feed,
    }));

  return {
    ...restRootResponse,
    response: {
      ...restPayload,
      feeds: feeds || [],
    },
  };
};
