import { IFeedElements } from '../../app/api/services/feeds/types';
import { Frame, IBody } from '../../app/api/services/rubrics/types';

export const getUniqueElementTypes = (data: IFeedElements[]): string[] => {
  if (!data) return;
  const uniqueTypes: Record<string, boolean> = {};
  for (const item of data) {
    uniqueTypes[item?.element_type] = true;
  }

  return Object.keys(uniqueTypes);
};

export const updateData = (data: IBody): IBody => {
  if (!data) return;
  const feed_id = data?.feed_bottom?.feed_id
    ? data?.feed_bottom?.feed_id
    : data?.feed_bottom?.id;
  const position = data?.elements?.length + 1;

  const newElement = {
    id: feed_id,
    position,
    type: 'feed',
  };

  data.elements.push(newElement as Frame<'feed'>);

  return data;
};
