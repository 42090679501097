import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import { placeholderValue } from './helpers';
import { Switch } from '../../../../../widgets';
import { fieldQuantitativeIndicator } from '../../../helpers';
import { IPropsFilterAndColumn } from '../../../types';
import { AttributeItemWrapper } from '../../AttributeItemWrapper';

import style from './InputNumberOrRange.module.scss';

export const InputNumberOrRange = ({
  filter,
  columnType,
}: IPropsFilterAndColumn): JSX.Element => {
  const { values, setFieldValue } = useFormikContext();
  const title = fieldQuantitativeIndicator[filter];

  const minValue = values?.[columnType]?.[filter]?.['gte'];
  const maxValue = values?.[columnType]?.[filter]?.['lte'];

  useEffect(() => {
    if (minValue !== maxValue) {
      setFieldValue(`active.${columnType}.${filter}`, true);
    }
  }, []);

  useEffect(() => {
    if (
      typeof values?.['active']?.[columnType]?.[filter] === 'boolean' &&
      !values?.['active']?.[columnType]?.[filter]
    ) {
      setFieldValue(`${columnType}.${filter}.lte`, minValue);
    }

    if (
      typeof values?.['active']?.[columnType]?.[filter] === 'boolean' &&
      values?.['active']?.[columnType]?.[filter] &&
      minValue === maxValue
    ) {
      setFieldValue(`${columnType}.${filter}.lte`, '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnType, filter, minValue, setFieldValue, values]);

  const handleMinValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? Number(e.target.value) : null;

    if (value === null) {
      setFieldValue(`${columnType}.${filter}.gte`, null);
      setFieldValue(`${columnType}.${filter}.lte`, null);

      return;
    }

    setFieldValue(`${columnType}.${filter}.gte`, value);
  };

  const handleMaxValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? Number(e.target.value) : null;

    if (value === null) {
      setFieldValue(`${columnType}.${filter}.lte`, null);

      return;
    }

    setFieldValue(`${columnType}.${filter}.lte`, value);
  };

  return (
    <AttributeItemWrapper
      filter={filter}
      columnType={columnType}
      title={title}
      mode="withSwitch"
      className={style.attributeWrapper}
    >
      <div className={style.content}>
        {!values?.['active']?.[columnType]?.[filter] ? (
          <input
            type="number"
            step="1"
            min={0}
            value={minValue !== null ? minValue : ''}
            placeholder={placeholderValue(title, false)[0]}
            className={style.input}
            onChange={handleMinValue}
          />
        ) : (
          <div className={style.rangeInput}>
            <input
              type="number"
              step="1"
              min={0}
              max={maxValue || undefined}
              value={minValue !== null ? minValue : ''}
              placeholder={`от ${placeholderValue(title, true)[0]}`}
              className={style.input}
              onChange={handleMinValue}
            />
            &mdash;
            <input
              type="number"
              step="1"
              min={minValue || 0}
              value={maxValue !== null ? maxValue : ''}
              placeholder={`до ${placeholderValue(title, true)[1]}`}
              className={style.input}
              onChange={handleMaxValue}
            />
          </div>
        )}
        <Switch
          label={`Задать диапазон`}
          field={`active.${columnType}.${filter}`}
          value={values?.['active']?.[columnType]?.[filter]}
          className={style.switch}
        />
      </div>
    </AttributeItemWrapper>
  );
};
