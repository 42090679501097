import cn from 'classnames';

import { FeedGroup } from '../../../../../../../../app/api/services/feedGroups/types';
import { typeOptions } from '../../../../../../../../shared/types';
import { Text, TextWithTooltip } from '../../../../../../../../widgets';

import style from './FlatFeedGroupView.module.scss';

interface IProps {
  title: string;
  feedGroup: FeedGroup;
  className?: string;
  isError?: boolean;
}

export const FlatFeedGroupView = ({
  feedGroup,
  title,
  className,
  isError = false,
}: IProps): JSX.Element => (
  <div className={cn(style.container, className)}>
    <span
      className={cn(style.title, { [style.errorTitle]: isError })}
    >{`${title}`}</span>

    {/* имя */}
    <Text text={feedGroup?.name} className={style.name} />
    {/* <span className={}>{feedGroup?.name ?  : null}</span> */}

    {/* состав */}
    <span className={style.composition}>
      <Text className={style.overflow} text={feedGroup?.feed_top?.name} />
      {feedGroup?.feeds
        ? feedGroup?.feeds.map((feed) => (
            <Text
              key={feed.id}
              className={cn(style.text, style.overflow)}
              text={feed.name}
            />
          ))
        : null}
      <Text className={style.overflow} text={feedGroup?.feed_bottom?.name} />
    </span>

    {/* тип элемента */}
    <span className={style.typeElement}>
      {feedGroup?.feed_top && (
        <p>
          {typeOptions[feedGroup?.feed_top?.element_type?.name] ||
            typeOptions[feedGroup?.feed_top?.collection?.collection_type?.name]}
        </p>
      )}
      {feedGroup?.feeds
        ? feedGroup?.feeds.map((feed) => (
            <span key={feed?.id} className={cn(style.text, style.typeElement)}>
              {typeOptions[feed?.element_type?.name] ||
                typeOptions[
                  feedGroup?.feed_top?.collection?.collection_type?.name
                ]}
            </span>
          ))
        : null}
      {feedGroup?.feed_bottom && (
        <p>
          {typeOptions[feedGroup?.feed_bottom?.element_type?.name] ||
            typeOptions[feedGroup?.feed_top?.collection?.collection_type?.name]}
        </p>
      )}
    </span>

    {/* вид ленты */}
    <span className={style.feedType}>
      {feedGroup?.feed_top && <p>{feedGroup?.feed_bottom?.feed_type?.name}</p>}
      {feedGroup?.feeds
        ? feedGroup?.feeds.map((feed) => (
            <span key={feed?.id} className={cn(style.text, style.feedType)}>
              {feed?.feed_type &&
                (typeof feed?.feed_type === 'string'
                  ? feed?.feed_type
                  : feed?.feed_type?.name)}
            </span>
          ))
        : null}
      {feedGroup?.feed_bottom && (
        <p>{feedGroup?.feed_bottom?.feed_type?.name}</p>
      )}
    </span>

    <span className={style.feedGroupId}>
      <TextWithTooltip
        text={feedGroup ? feedGroup.uuid : null}
        className={style.tooltip}
      />
    </span>
  </div>
);
