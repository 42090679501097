import cn from 'classnames';

import { Feed } from '../../../../../../../../app/api/services/feeds/types';
import { getUniqueElementTypes } from '../../../../../../../../utils/feeds';
import { TextWithTooltip } from '../../../../../../../../widgets';

import style from './FlatFeedViewInRubric.module.scss';

interface IProps {
  title: string;
  feed: Feed;
  isError?: boolean;
  className?: string;
}

export const FlatFeedViewInRubric = ({
  feed,
  title,
  className,
  isError = false,
}: IProps): JSX.Element => (
  <div className={cn(style.container, className)}>
    <span
      className={cn(style.title, { [style.errorTitle]: isError })}
    >{`${title}`}</span>
    <span className={cn(style.text, style.name)}>{feed?.name}</span>
    <span className={cn(style.text, style.typeElement)}>
      {getUniqueElementTypes(feed?.feed_elements)
        ?.join(', ')
        .split(', ')
        .map((feed_element, index) => (
          <span key={index}>
            {feed_element}
            {index !== getUniqueElementTypes(feed?.feed_elements).length - 1
              ? ', '
              : ''}
          </span>
        ))}
    </span>
    <span className={cn(style.text, style.feedType)}>
      {feed?.feed_type &&
        (typeof feed?.feed_type === 'string'
          ? feed?.feed_type
          : feed?.feed_type?.name)}
    </span>
    <span className={style.feedId}>
      <TextWithTooltip
        text={feed ? feed.uuid : null}
        className={style.tooltip}
      />
    </span>
  </div>
);
